<template>
    <div>
        <!-- Table Container Card -->
        <b-card>
            <div class="m-2">
                <validation-observer ref="createAttribute" #default="{ invalid }">
                    <b-row class="filter-row" style="padding: 0 10px">
                        <b-col cols="12" md="6" class="px-0 pb-1">
                            <h4 style="font-size: 18px;font-weight: 600;">ثبت ویژگی جدید</h4>
                            <p style="font-size: 14px;font-weight: 400;">ویژگی های جدید را از این قسمت بارگذاری کنید.</p>
                        </b-col>
                        <b-col class="px-0 d-none d-md-block" dir="ltr" cols="12" md="6">
                            <b-button class="d-flex justify-content-center align-items-center" variant="primary"
                                type="submit" :disabled="invalid || isLoading" @click="createAttribute">
                                <span class="text-nowrap">ثبت ویژگی</span>
                                <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
                            </b-button>
                        </b-col>
                    </b-row>
                    <!-- Table Top -->

                    <b-row>
                        <b-col class="p-0 mb-1" cols="12" md="4" style="padding: 0 10px !important">
                            <h6>عنوان ویژگی</h6>
                            <validation-provider #default="{ errors }" name="label" rules="required">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-form-input v-model="formData.label" class="d-inline-block"
                                        placeholder="عنوان ویژگی را وارد کنید"
                                        :state="errors.length > 0 ? false : null" />
                                </div>
                                <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col class="p-0 mb-1" cols="12" md="4" style="padding: 0 10px !important">
                            <h6>اسلاگ</h6>
                            <validation-provider #default="{ errors }" name="slug" rules="required">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-form-input v-model="formData.slug" class="d-inline-block"
                                        placeholder="اسلاگ را انگلیسی وارد کنید"
                                        :state="errors.length > 0 ? false : null" />
                                </div>
                                <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col class="p-0 mb-1" cols="12" md="4" style="padding: 0 10px !important">
                            <h6>میزان </h6>
                            <validation-provider #default="{ errors }" name="value" rules="required">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-form-input dir="ltr" v-model="formDataTwo.value" class="d-inline-block" style="text-align: right;"
                                        placeholder="مقدار را وارد کنید" :state="errors.length > 0 ? false : null" />
                                </div>
                                <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col class="px-0 d-md-none" dir="ltr" cols="12" md="6" style="padding: 0 10px !important">
                            <b-button class="d-flex justify-content-center align-items-center" variant="primary"
                                type="submit" :disabled="invalid || isLoading" @click="createAttribute">
                                <span class="text-nowrap">ثبت ویژگی</span>
                                <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>

            <!-- Table -->
            <LoadingComponent v-if="loading" />
            <b-col style="padding: 0 15px !important" v-else>
                <div v-if="attributes.length > 0">
                    <div class="pt-1 pb-1 overflow" v-if="attributes.length > 0">
                        <vue-good-table :sort-options="{ enabled: false }" :columns="columns" :rows="attributes"
                            :rtl="false" class="w-100">
                            <div slot="emptystate" class="w-100 text-center">
                                موردی یافت نشد
                            </div>
                            <template slot="table-row" slot-scope="props" class="mx-auto">
                                <!-- Column: Name -->
                                <span v-if="props.column.field === 'id'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.id }}</span>
                                </span>
                                <span v-if="props.column.field === 'label'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.label }}</span>
                                </span>
                                <span v-if="props.column.field === 'slug'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.slug }}</span>
                                </span>

                                <span v-if="props.column.field === 'status'" class="text-nowrap">
                                    <span class="text-nowrap">{{ props.row.status }}</span>
                                </span>

                                <!-- Column: Action -->
                                <span v-else-if="props.column.field === 'action'" style="width: 100px !important">
                                    <span>
                                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret
                                            :right="true">
                                            <template v-slot:button-content>
                                                <feather-icon icon="MoreVerticalIcon" size="16"
                                                    class="text-body align-middle mr-25" />
                                            </template>
                                            <b-dropdown-item @click="moveToEditAttribute(props.row.id)">
                                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                                <span>ویرایش</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="deletePrompt(props.row.id)">
                                                <feather-icon icon="TrashIcon" class="mr-50" />
                                                <span>حذف</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                    <b-pagination v-model="currentPage" :total-rows="totalAttributes" :per-page="perPage" first-number
                        last-number align="left" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0 p-0"
                        dir="ltr">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
                <div v-else>
                    <h3 class="text-center">موردی یافت نشد</h3>
                </div>
            </b-col>
        </b-card>
    </div>
</template>
<script>
import {
    BAvatar,
    BCard,
    BSpinner,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BPagination,
    BTable,
    BDropdown,
    BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import { VueGoodTable } from "vue-good-table";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoadingComponent from "../../components/LoadingComponent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        VueGoodTable,
        BAvatar,
        BCard,
        BSpinner,
        BFormInput,
        BRow,
        BTable,
        BCol,
        BButton,
        BPagination,
        BDropdownItem,
        BDropdown,
        ToastificationContent,
        LoadingComponent,
    },
    data() {
        return {
            required,
            isLoading: false,
            loading: false,
            attributes: [],
            attributesLength: 0,
            attributesTray: [],
            totalAttributes: 0,
            perPage: 15,
            currentPage: 1,
            columns: [
                {
                    label: "شناسه",
                    field: "id",
                },
                {
                    label: "عنوان",
                    field: "label",
                },
                {
                    label: "اسلاگ",
                    field: "slug",
                },
                {
                    label: "عملیات",
                    field: "action",
                },
            ],
            formData: {
                label: null,
                slug: null,
            },
            formDataTwo: {
                attribute_id: null,
                value: null,
            },
        };
    },
    methods: {
        getAllAttributes() {
            this.loading = true;
            this.attributes = [];
            axios
                .get("/api/v1/admin/posts/attributes", { params: { page: this.currentPage } })
                .then((response) => {
                    this.attributesLength = response.data.data.length;
                    this.totalAttributes = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    if (this.attributesLength > 0) {
                        this.attributesTray = response.data.data;
                        this.attributes = [];
                        this.attributesTray.forEach((item) => {
                            this.attributes.push({
                                id: item.id,
                                label: item.label,
                                slug: item.slug,
                            });
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        deletePrompt(id) {
            this.$bvModal
                .msgBoxConfirm('آیا از حذف مطمئن هستید؟', {
                    title: '',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'بله',
                    cancelTitle: 'خیر',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value == true) {
                        this.deleteAttribute(id)
                    }
                })
        },
        deleteAttribute(id) {
            axios
                .post(`/api/v1/admin/posts/attributes/${id}/delete`)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "ویژگی حذف شد",
                            icon: "CheckIcon",
                            text: "ویژگی با موفیقت حذف شد.",
                            variant: "info",
                        },
                    });
                    this.getAllAttributes();
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        moveToEditAttribute(id) {
            router.push(`/attributes/update/${id}`);
        },
        createAttribute() {
            this.isLoading = true;
            axios
                .post("/api/v1/admin/posts/attributes/create", this.formData)
                .then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.formDataTwo.attribute_id = response.data.data.id
                        this.createAttributeValue()
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        createAttributeValue() {
            this.isLoading = true;
            axios
                .post("/api/v1/admin/posts/attributes/value/create", this.formDataTwo)
                .then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "ویژگی ساخته شد",
                                icon: "CheckIcon",
                                text: "ویژگی با موفیقت ساخته شد",
                                variant: "success",
                            },
                        });
                    }
                    this.getAllAttributes()
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        // persianRegexTest(text) {
        //     const persianRegex = /^[\u0600-\u06FF\s]+$/
        //     if (text && !persianRegex.test(text)) {
        //         console.log(false);
        //         return false
        //     } else {
        //         console.log(true);
        //         return true
        //     }
        // },
        // englishRegexTest(text) {
        //     const englishRegex = /^[a-zA-Z]*$/
        //     if (text && !englishRegex.test(text)) {
        //         console.log(false);
        //         return false
        //     } else {
        //         console.log(true);
        //         return true
        //     }
        // },
        // digitsRegexTest(text) {
        //     const digitsRegex = /^[0-9]+$/
        //     if (text && !digitsRegex.test(text)) {
        //         console.log(false);
        //         return false
        //     } else {
        //         console.log(true);
        //         return true
        //     }
        // },
    },
    mounted() {
        this.getAllAttributes();
        this.$watch("currentPage", (newCurrentPage) => {
            this.getAllAttributes();
        });
    },
    unmounted() {
        this.$watch("currentPage");
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
    width: 90px;
}
</style>